import ClarivizLenses1 from "../assets/images/clariviz-lenses/ClarivizLenses-1.jpg";
import ClarivizLenses2 from "../assets/images/clariviz-lenses/ClarivizLenses-2.jpg";
import ClarivizLenses3 from "../assets/images/clariviz-lenses/ClarivizLenses-3.jpg";
import ClarivizLenses4 from "../assets/images/clariviz-lenses/ClarivizLenses-4.jpg";
import ClarivizLensesImg from "../assets/images/clariviz-lenses/ClarivizLensesImg.webp";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizLenses = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.CLARIVIZLENSES);
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Lenses - Superior Quality Eyewear Solutions"
        description="Experience Clariviz lenses: The gold standard in lens accuracy and clarity. 
        Welcome to the world of state-of-the-art optical solutions for all your visual needs."
        keywords="Clariviz, lenses, optical solutions, eyewear, vision correction"
        name="Clariviz"
        type="website"
        canonicallink={UrlLink}
      />
      <div className="relative w-full h-[35vh] xs:h-[40vh] small:h-[45vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh] z-2">
        <img
          src={ClarivizLensesImg}
          alt="Main header pic"
          className="w-[100%] h-[100%] object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-10"></div>
        <div className="absolute top-0 w-full h-full flex items-center justify-end">
          <div
            className="text-white w-2/4 small:w-2/4 z-1 
          md:w-3/6 mlg:w-2/4 lg:w-2/5 flex flex-col gap-4 px-3"
          >
            <h1 className="text-xl xs:text-2xl small:text3xl md:text-4xl font-bold drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]">
              EXPERIENCE CLARIVIZ
            </h1>
            <p
              className="text-tinybase xs:text-base small:text-md md:text-2xl leading-snug w-3/4
            drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)] font-light text-borde text-balance"
            >
              The new Gold Standard in lens accuracy and clarity
            </p>
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Lenses
        </h1>
        <p className="text-base text-black leading-loose md:leading-8 w-[98%]">
          Welcome to the world of Clariviz Lenses – state-of-the-art optical
          solutions for literally all of your visual needs. Through the most
          stringent processes and procedures, our lenses are crafted with
          professional pride, to offer you the visual acuity you require and
          deserve.
        </p>
        <p className="text-base text-black leading-loose md:leading-8 w-[98%]">
          Clariviz lenses deliver completely reliable performance, ensuring that
          your vision remains crystal clear no matter the circumstances. So
          welcome to our world. See better, and enhance the quality of your
          life, with gold-standard lenses by Clariviz!
        </p>
      </div>
      <ImageTextLayout
        imageSrc={ClarivizLenses1}
        subtitle="Clariviz Z Series DS"
        title="The most technologically advanced progressive lens, tailored for you."
        description="Clariviz Z Series DS lenses adapt to the demands of modern life. The technology meets the challenging visual needs of the most active presbyopes, who require clear and stable vision, even when they’re engaged in highly dynamic activities.."
        buttonTxt="Z Series DS"
        buttonTxt2="Z Series 2.0"
        buttonTxt3="Z Series 1.0"
      />
      <ImageTextLayout
        imageSrc={ClarivizLenses2}
        title="Clariviz Technologies"
        description="Clariviz offers, in all of our lenses, ideal combinations of the 
        following nine world-class, cutting- edge technologies. Thus ensuring that your 
        lenses provide you with uncompromisingly accurate vision, in all conditions."
        buttonTxt="Find Out More"
        reverse
      />
      <ImageTextLayout
        imageSrc={ClarivizLenses3}
        title="Clariviz Coatings"
        description="Different lens coatings serve various critical functions: 
        including eye health protection, convenience and durability. Take a look at the coatings 
        we have on offer."
        buttonTxt="Find Out More"
      />
      <ImageTextLayout
        imageSrc={ClarivizLenses4}
        title="Clariviz Personalization"
        description="Get personalization like never before, with technologies that perfectly 
        match your frame, lenses and lens coatings."
        buttonTxt="Find Out More"
        reverse
      />
    </section>
  );
};

export default ClarivizLenses;
