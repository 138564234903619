import headerImg from "../assets/images/cleardrive-pal/headerImg.webp";
import ClearDrivePAL from "../assets/images/cleardrive-pal/CLARIVIZ CLEARDRIVE PAL.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizClearDrivePAL = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.CLEARDRIVEPAL);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz ClearDrive PAL",
    description:
      "Clariviz ClearDrive PAL are free-form progressive lenses with a night vision zone, designed for drivers to enhance visual clarity and reduce eye fatigue.",
    url: UrlLink
  });
  
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz ClearDrive PAL - Advanced Night Vision Progressive Lenses for Drivers"
        description="Discover Clariviz ClearDrive PAL, free-form progressive lenses designed for drivers with a dedicated night vision zone. These lenses reduce eye fatigue, correct night myopia, and enhance visual acuity for safer driving. Perfect for both day and night driving with optimized near vision for dashboard and mirrors."
        keywords="Clariviz Cleardrive PAL, progressive lenses for drivers, night vision lenses, driving vision correction, free-form progressive lenses, night myopia correction, prescription driving lenses, personalized progressive lenses, eyewear for night driving, custom lenses for drivers"
        name="Clariviz Cleardrive PAL"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz ClearDrive PAL night vision progressive lenses for drivers"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Cleardrive PAL
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Tailored free-form progressive lenses designed specifically for
          drivers, featuring a specific night vision zone. Clariviz Cleardrive
          PAL lens is well-suited for driving due to its customized power
          distribution. The night vision zone in this personalized free-form
          progressive lens addresses refractive differences between day and
          night, enhancing visual acuity and minimizing eye fatigue.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ClearDrivePAL} alt="Clariviz ClearDrive PAL progressive lenses for driving vision correction" />
        </div>
        {/* 3 Column */}
        <div className="w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Drivers looking for high-quality and comfortable progressive
                lenses.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Drivers who have the need to drive at night and are searching
                for a safer solution that corrects night myopia
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Improved vision while driving
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                A safe option for the night drive, due to a special zone that
                compensates for and corrects the night myopia effects
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Optimized near vision to provide a better focus on the dashboard
                and mirrors
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Easy focus and agile eye movement
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Available for all types of prescriptions and additional powers
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-full md:w-[90%] flex flex-col gap-4">
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  18 mm
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
you're never far from the exceptional products and services you love. Whether you're planning 
a visit or need to check availability, we're here to make your shopping experience seamless and 
convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizClearDrivePAL;
