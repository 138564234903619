import SpatialVision from "../assets/images/lenstechnologies/Spatial Vision.png";
import EyeFocusProfiling from "../assets/images/lenstechnologies/Eye Focus Profiling.png";
import VisionStability from "../assets/images/lenstechnologies/Vision Stability.png";
import FitConfiguration from "../assets/images/lenstechnologies/Fit Configuration.png";
import RayTracing from "../assets/images/lenstechnologies/Ray Tracing.png";
import BinocularVision from "../assets/images/lenstechnologies/Binocular Vision.png";
import DualSideTechnology from "../assets/images/lenstechnologies/Dual Side Technology.png";

const LensTechnologyData = [
  {
    imgSrc: SpatialVision,
    title: "Spatial Vision",
    description:
      "Spatial Vision Technology optimizes the range within which an object can be moved closer to or farther away from the eye without a noticeable change in the focus of the image. This improves the eye’s ability to focus on objects near and far, resulting in exceptional sharpness perception and extended focus areas. This technology ensures that wearers experience clear and sharp vision, even in dynamic environments where objects are constantly changing distances from the eye. It enables smooth and rapid transitions across visual zones, especially when looking down to focus on nearby objects.",
  },
  {
    imgSrc: EyeFocusProfiling,
    title: "Eye Focus Profiling",
    description:
      "Eye Focus Profiling Technology maximizes the clear viewing area and significantly reduces perceived blur in the peripheral areas of the lens. By taking into consideration the wearer’s accommodative reserve, it effectively minimizes oblique aberrations, achieving an absolute reduction in defocus and increasing the visual performance of the lenses.",
  },
  {
    imgSrc: VisionStability,
    title: "Vision Stability",
    description:
      "Vision Stability Technology significantly reduces the swim effect when moving by controlling the mean power error. This virtually eliminates image distortions and enhances image stability, providing the wearer with a comfortable and clear vision experience.",
  },
  {
    imgSrc: FitConfiguration,
    title: "Individual Fit Configuration",
    description:
      "Optimizes the vision zones based on the unique data of the wearer, including their prescription, facial morphology, frame shape, and use position. This allows us to create a fully personalized lens.",
  },
  {
    imgSrc: FitConfiguration,
    title: "Frame Fit Configuration",
    description:
      "Regardless of the shape or size of the frame, our technology adjusts the lens design structure to provide optimal performance.",
  },
  {
    imgSrc: FitConfiguration,
    title: "Lens Fit Configuration",
    description:
      "Optimizes the aesthetic appearance of the lens by utilizing the optimal base curves and thinning prism, resulting in the thinnest possible lenses, even with a high prescription. Through thickness optimization, our technology provides a sleek and modern aesthetic for the wearer.",
  },
  {
    imgSrc: RayTracing,
    title: "Ray Tracing",
    description:
      "Thanks to point-by-point optimization over the entire lens surface, Ray Tracing Technology provides precise vision at every distance and in every direction of gaze. The perceived power distribution is consistent, regardless of prescription or frame shape. Wearers enjoy a more natural visual experience with much less distortion than a conventional digital lens.",
  },
  {
    imgSrc: BinocularVision,
    title: "Binocular Vision",
    description:
      "Binocular Vision Technology ensures a symmetrical design that enables clear image and vision, regardless of any difference in prescriptions, for both lenses in intermediate and near areas. This ensures that the wearer experiences a comfortable and balanced vision, with optimal performance for all activities.",
  },
  {
    imgSrc: DualSideTechnology,
    title: "Dual Side Technology",
    description:
      "Pushing the boundaries of free-form technology, the lens’s special geometry enables wider fields of view and sharper vision, especially in the reading zone. The variable base curve achieves a better balance between visual quality and lens thickness, particularly for high prescriptions.",
  },
];

export default LensTechnologyData;
