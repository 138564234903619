import { Helmet } from "react-helmet-async";

const SEO = ({
  title,
  description,
  keywords,
  name,
  type,
  canonicallink,
  structuredData,
}: any) => {
  const image = "../assets/images/header.webp"
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />

      {/* OpenGraph Meta Tags */}
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content="Clariviz" />
      <meta property="og:image" content={image} />
      <meta property="og:url" content={canonicallink} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:creator" content={name} />
      <meta name="twitter:card" content={type} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />

      <link rel="canonical" href={canonicallink} />

      {structuredData && (
        <script type="application/ld+json">{structuredData}</script>
      )}
    </Helmet>
  );
};

export default SEO;
