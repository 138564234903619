import ProView20 from "../assets/images/proview20/CLARIVIZ PROVIEW 2.0.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import headerImg from "../assets/images/proview20/headerImg.webp";
import { ImageTextLayout, SEO } from "../components";
import { ProViwe20 } from "../utils/ClarivizSeriesData";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizProView20 = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.PROVIEW20);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz ProView 2.0 Lenses",
    description:
      "Clariviz Proview 2.0 offers superior image stability, comfort, and performance for users seeking non-personalized progressive lenses.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Proview 2.0 - High-Performance Progressive Lenses for Quality Vision"
        description="Explore Clariviz Proview 2.0, progressive lenses designed for quality 
        vision and comfort in all visual zones. Ideal for those seeking effective, non-personalized 
        progressive lenses with image stability and ease of use."
        keywords="Clariviz Proview 2.0, progressive lenses for quality vision, 
        vision correction lenses, balanced visual zones, non-personalized progressive lenses, 
        image stability lenses, comfort lenses for presbyopia, lens technology for visual comfort"
        name="Clariviz Proview 2.0 Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz ProView 2.0 progressive lenses for quality vision and comfort"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Proview 2.0
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clariviz Proview 2.0 is made for those users who do not demand a
          personalized lens but are still looking for an option that gives good
          vision and comfort in all the visual zones. Clariviz Proview 2.0
          provides their wearers with quality, performance, comfort and better
          image stability than other similar progressive designs.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ProView20} alt="Clariviz ProView 2.0 lenses" />
        </div>
        {/* 3 Column */}
        <div className="pb-12 w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Progressive solution for those searching for an effective and
                non-personalized design.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Good performance in all visual fields.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Reduced swim effect due to Steady Methodology.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Comfortable and easy to adapt.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-full md:w-[90%] flex flex-col gap-4">
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  14 mm | 16 mm | 18 mm
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  Non-Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Technologies */}
        <div>
          <h1 className="text-3xl text-primary font-bold">Technologies</h1>
          <div className="p-8 pb-0">
            <div className="grid grid-cols-1 md:grid-cols-3">
              {ProViwe20.map((item, index) => (
                <div className="border p-[0.85rem]" key={index}>
                  <div className="flex items-center gap-x-4">
                    <div className="w-[25%] h-[25%]">
                      <img
                        src={item.imgSrc}
                        alt={item.altTxt}
                        className="w-full h-full"
                      />
                    </div>
                    <div className="font-bold text-primary text-base">
                      {item.title}
                    </div>
                  </div>
                  <p className="pt-4 tracking-wide">{item.description}</p>
                </div>
              ))}
              <div className="hidden md:flex md:border-t md:border-b md:p-[0.85rem]"></div>
              <div className="hidden md:flex md:border md:p-[0.85rem]"></div>
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
      you're never far from the exceptional products and services you love. Whether you're planning 
      a visit or need to check availability, we're here to make your shopping experience seamless and 
      convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizProView20;
