import {
  BenDigitalPal,
  Careers,
  ClarivizByBenFranklin,
  ClarivizClearDrivePAL,
  ClarivizClearDriveSV,
  ClarivizDigipro10,
  ClarivizDigipro20,
  ClarivizLenses,
  ClarivizOffice,
  ClarivizProView10,
  ClarivizProView20,
  ClarivizRxBiFocal,
  ClarivizSingleVision,
  ClarivizZSeries10,
  ClarivizZSeries20,
  ClarivizZSeriesDS,
  Contact,
  CookiePolicy,
  ErrorPage,
  HercueCoating,
  Home,
  LensColours,
  LensManufacturingUnit,
  LensPersonalization,
  LensTechnologies,
  PixelDigi,
  PrivacyPolicy,
  Quichromes,
  StoreLocator,
  ULTIPROBlueCoating,
  ULTIPROUVCOATING,
} from "../pages";

import { Layout } from "../components";
import NavStrings from "./navigationStrings";

interface IRouteComponents {
  path: string;
  element: JSX.Element;
  errorElement?: JSX.Element;
  children?: any;
}
const routePaths = Object.freeze({
  home: NavStrings.HOME,
  calrivizbybenfranklin: NavStrings.CLARIVIZBYBENFRANKLIN,
  lensmanufacturingunit: NavStrings.LENSMANUFACTURINGUNIT,
  contactus: NavStrings.CONTACTUS,
  storelocator: NavStrings.STORELOCATOR,
  zseriesds: NavStrings.ZSERIESDS,
  zseries20: NavStrings.ZSERIES20,
  zseries10: NavStrings.ZSERIES10,
  digipro20: NavStrings.DIGIPRO20,
  digipro10: NavStrings.DIGIPRO10,
  proview20: NavStrings.PROVIEW20,
  proview10: NavStrings.PROVIEW10,
  cleardrivepal: NavStrings.CLEARDRIVEPAL,
  clarivizoffice: NavStrings.CLARIVIZOFFICE,
  pixeldigi: NavStrings.PIXELDIGI,
  quichromes: NavStrings.QUICHROMES,
  clarivizsinglevision: NavStrings.CLARIVIZSINGLEVISION,
  cleardrivesv: NavStrings.CLEARDRIVESV,
  clarivizrxbifocal: NavStrings.RXBIFOCAL,
  bendigipal: NavStrings.BENDIGITALPAL,
  clarivizlenses: NavStrings.CLARIVIZLENSES,
  lenstechnologies: NavStrings.LENSTECHNOLOGIES,
  castlecoating: NavStrings.ULTIPROUVCOATING,
  castlebluecoating: NavStrings.ULTIPROBLUECOATING,
  potenscoating: NavStrings.HERCUECOATING,
  lenscolours: NavStrings.LENSCOLOUR,
  lenspersonalization: NavStrings.LENSPERSONALIZATION,
  privacypolicy: NavStrings.PRIVACYPOLICY,
  cookiepolicy: NavStrings.COOKIEPOLICY,
  careers: NavStrings.CAREERS,
});
export const AllRoutes: Array<IRouteComponents> = [
  {
    path: routePaths.home,
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        index: true,
        path: routePaths.calrivizbybenfranklin,
        element: <ClarivizByBenFranklin />,
      },
      {
        index: true,
        path: routePaths.lensmanufacturingunit,
        element: <LensManufacturingUnit />,
      },
      {
        index: true,
        path: routePaths.contactus,
        element: <Contact />,
      },
      {
        index: true,
        path: routePaths.storelocator,
        element: <StoreLocator />,
      },
      {
        index: true,
        path: routePaths.clarivizlenses,
        element: <ClarivizLenses />,
      },
      {
        index: true,
        path: routePaths.zseriesds,
        element: <ClarivizZSeriesDS />,
      },
      {
        index: true,
        path: routePaths.zseries20,
        element: <ClarivizZSeries20 />,
      },
      {
        index: true,
        path: routePaths.zseries10,
        element: <ClarivizZSeries10 />,
      },
      {
        index: true,
        path: routePaths.digipro20,
        element: <ClarivizDigipro20 />,
      },
      {
        index: true,
        path: routePaths.digipro10,
        element: <ClarivizDigipro10 />,
      },
      {
        index: true,
        path: routePaths.proview20,
        element: <ClarivizProView20 />,
      },
      {
        index: true,
        path: routePaths.proview10,
        element: <ClarivizProView10 />,
      },
      {
        index: true,
        path: routePaths.cleardrivepal,
        element: <ClarivizClearDrivePAL />,
      },
      {
        index: true,
        path: routePaths.clarivizoffice,
        element: <ClarivizOffice />,
      },
      {
        index: true,
        path: routePaths.pixeldigi,
        element: <PixelDigi />,
      },
      {
        index: true,
        path: routePaths.quichromes,
        element: <Quichromes />,
      },
      {
        index: true,
        path: routePaths.clarivizsinglevision,
        element: <ClarivizSingleVision />,
      },
      {
        index: true,
        path: routePaths.cleardrivesv,
        element: <ClarivizClearDriveSV />,
      },
      {
        index: true,
        path: routePaths.clarivizrxbifocal,
        element: <ClarivizRxBiFocal />,
      },
      {
        index: true,
        path: routePaths.bendigipal,
        element: <BenDigitalPal />,
      },
      {
        index: true,
        path: routePaths.lenstechnologies,
        element: <LensTechnologies />,
      },
      {
        index: true,
        path: routePaths.castlecoating,
        element: <ULTIPROUVCOATING />,
      },
      {
        index: true,
        path: routePaths.castlebluecoating,
        element: <ULTIPROBlueCoating />,
      },
      {
        index: true,
        path: routePaths.potenscoating,
        element: <HercueCoating />,
      },
      {
        index: true,
        path: routePaths.lenscolours,
        element: <LensColours />,
      },
      {
        index: true,
        path: routePaths.lenspersonalization,
        element: <LensPersonalization />,
      },
      {
        index: true,
        path: routePaths.privacypolicy,
        element: <PrivacyPolicy />,
      },
      {
        index: true,
        path: routePaths.cookiepolicy,
        element: <CookiePolicy />,
      },
      {
        index: true,
        path: routePaths.careers,
        element: <Careers />,
      },
    ],
    errorElement: <ErrorPage />,
  },
];
