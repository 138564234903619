import React from "react";
import { SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const CookiePolicy = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.COOKIEPOLICY);
  return (
    <>
      <SEO
        title="Cookie Policy - Clariviz"
        description="Read our Cookie Policy to understand how we use cookies on our website to improve your browsing experience and ensure accurate site traffic measurement."
        keywords="cookie policy, data tracking, cookies, site traffic, privacy"
        name="Cookie Policy"
        type="website"
        canonicallink={UrlLink}
      />
      <section className="pt-8 pb-12 flex flex-col px-custom-padding min-h-[80vh]">
        <h1 className="text-3xl text-primary font-bold">Cookie Policy</h1>
        <h4 className="mt-2 text-primary font-medium text-md">
          A cookie is a small file saved by your web browser on your computer
          that can capture certain aspects of your engagement with our website.
          Cookies also enable us to compile data on site traffic and
          interactions to improve your experience on our website.
        </h4>
        <p className="text-base text-black leading-8 w-[98%] mt-8">
          Additionally, we may use trusted third-party services to track this
          data on our behalf. In such cases, we may arrange for a common
          identifier to be shared between the third party and us to ensure
          accuracy in the measurement of traffic and engagement. If you choose,
          you can change your cookie settings to accept or disable cookies.
          However, should you choose to disable them, some of the features that
          make the website more efficient may not run properly. You can also
          choose to be notified by your computer each time a cookie is sent. We
          do not sell, trade or in any way transfer your personal information to
          outside parties, nor do we include or offer third-party products on
          our website.
        </p>
      </section>
    </>
  );
};

export default CookiePolicy;
