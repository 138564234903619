import ProView10 from "../assets/images/proview10/CLARIVIZ PROVIEW 1.0.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import headerImg from "../assets/images/proview10/headerImg.webp";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizProView10 = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.PROVIEW10);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz ProView 1.0 Lenses",
    description:
      "Clariviz ProView 1.0 lenses are digital non-personalized progressive lenses designed for experienced wearers, offering balanced vision across near, intermediate, and distance zones.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz ProView 1.0 - Progressive Lenses for Balanced Vision and Comfort"
        description="Clariviz Proview 1.0 is a well-balanced, digital progressive lens offering expansive near and far visual fields for experienced wearers."
        name="Clariviz Proview 1.0 Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz ProView 1.0 progressive lenses for balanced vision"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Proview 1.0
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          A digital and non-personalized design for experienced progressive
          users who want a good option with balanced visual distances. Clariviz
          Proview 1.0 is a standard and comfortable design that can satisfy the
          wearer in the near, intermediate, and distance zones.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ProView10} alt="Clariviz ProView 1.0 lenses for experienced users" />
        </div>
        {/* 3 Column */}
        <div className="w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Experienced or progressive wearers looking for balanced visual
                fields.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Well-balanced foundational lens.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Expansive near and far visual fields.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Effective performance for regular use.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-full md:w-[90%] flex flex-col gap-4">
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  14 mm | 16 mm | 18 mm
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  Non-Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
  you're never far from the exceptional products and services you love. Whether you're planning 
  a visit or need to check availability, we're here to make your shopping experience seamless and 
  convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizProView10;
