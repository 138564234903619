import headerImg from "../assets/images/clariviz-single-vision/headerImg.webp";
import ClarivizSV from "../assets/images/clariviz-single-vision/CLARIVIZ SINGLE VISION.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizSingleVision = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.CLARIVIZSINGLEVISION);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz Single Vision Lenses",
    description:
      "Clariviz Single Vision Lenses provide sharp and clear vision for near or far distances, ideal for young adults, active individuals, and digital users.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Single Vision Lenses - Clear Vision for Near and Far"
        description="Clariviz Single Vision Lenses provide exceptional clarity for near or far vision 
        correction, ideal for young adults, active individuals, and digital device users."
        keywords="Clariviz Single Vision, single vision lenses, vision correction, 
        nearsightedness, farsightedness, astigmatism, digital lifestyle, 
        active individuals, readers, single vision eyeglasses, prescription lenses"
        name="Clariviz Single Vision Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz Single Vision lenses for clear vision"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-black font-bold">
          Clariviz Single Vision
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clariviz Single Vision Lenses are unmatched for those who need near or
          far vision correction only. Single vision lenses have the same optical
          power throughout the entire lens, and are designed to correct one type
          of vision problem, such as nearsightedness (myopia), farsightedness
          (hyperopia), or astigmatism.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ClarivizSV} alt="Clariviz Single Vision prescription lenses" />
        </div>
        {/* 3 Column */}
        <div className="w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-2 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                <span className="font-semibold text-black">
                  Young Adults and Children:
                </span>{" "}
                For those who require correction for one field of vision,
                typically distance.
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                <span className="font-semibold text-black">
                  Individuals with a Digital Lifestyle:
                </span>{" "}
                Single vision lenses can be optimized for intermediate distances
                for those who spend significant time on digital devices.
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                <span className="font-semibold text-black">
                  Active Individuals:
                </span>{" "}
                They are ideal for sports and other physical activities where
                clear distance vision is essential.
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                <span className="font-semibold text-black">Readers:</span>{" "}
                Customized single vision lenses can be made for near tasks like
                reading.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                Unmatched clarity for people with vision issues, providing sharp
                and clear vision for specific distances.
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                They allow accurate focus during various activities, such as
                reading, playing sports, or driving.
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                Can be personalized for different prescriptions, frames, and
                come in various materials and coatings.
              </li>
              <li className="list-disc text-base text-black leading-8 w-full md:w-[98%]">
                Simple to use without the need to switch between different
                glasses for different activities.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
you're never far from the exceptional products and services you love. Whether you're planning 
a visit or need to check availability, we're here to make your shopping experience seamless and 
convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizSingleVision;
