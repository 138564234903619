import headerImg from "../assets/images/rxbifocal/headerImg.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizRxBiFocal = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.RXBIFOCAL);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz RX Bi Focal Lenses",
    description:
      "Clariviz RX Bi Focal lenses provide dual vision correction for both near and far vision, ideal for those with presbyopia.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz RX Bi Focal Lenses - Vision Correction for Near and Far"
        description="Explore Clariviz RX Bi Focal lenses for dual vision correction, offering dual optical powers 
        for both near and far vision correction. Ideal for those with presbyopia to see clearly at both near and far distances"
        keywords="Clariviz RX Bi Focal, bifocal lenses, dual vision lenses, near and far vision correction,
         presbyopia lenses, versatile lenses, dual optical powers, bifocals for presbyopia, RX bifocal lenses"
        name="Clariviz RX Bi Focal Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz RX Bi Focal lenses for near and far vision correction"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz RX Bi Focal
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clariviz RX Bi Focal lenses are prescription lenses that have two
          distinct optical powers to correct vision at different distances. They
          are designed for those who need help with both near and far vision,
          typically due to presbyopia, an age-related condition that affects the
          eye&apos;s ability to focus on close objects. They provide clear
          vision for both distance and near tasks within one pair of glasses,
          eliminating the need to switch between different pairs of glasses.
        </p>

        {/* 3 Column */}
        <div className="pt-4 w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-2 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-lg text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Popular choice for those who require a simple and effective
                solution for their vision needs at multiple distances.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-lg text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                <span className="font-semibold text-black">Convenience: </span>
                They combine two prescriptions in one lens, which means you
                don&apos;t have to carry multiple pairs of glasses.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                <span className="font-semibold text-black">
                  Cost-Effective:{" "}
                </span>
                They can be more economical than purchasing two separate pairs
                of glasses or investing in more advanced progressive lenses.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                <span className="font-semibold text-black">
                  Ease of Adaptation:{" "}
                </span>
                The visible lines on the lenses can help users know where to
                look to see clearly at different distances.
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                <span className="font-semibold text-black">Versatility: </span>
                They provide a wider lens area for reading, making activities
                like reading or working on a computer more comfortable.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
you're never far from the exceptional products and services you love. Whether you're planning 
a visit or need to check availability, we're here to make your shopping experience seamless and 
convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizRxBiFocal;
