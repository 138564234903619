import HeaderImg from "../assets/images/about-us/clarivizby-benf/header.jpg";
import BecomePartner from "../assets/images/BecomePartner.jpeg";
import Careers from "../assets/images/Careers.jpg";
import StoreImg from "../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizByBenFranklin = () => {
  const UrlLink = UrlConstants.base_url.concat(
    NavStrings.CLARIVIZBYBENFRANKLIN
  );
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz by Ben Franklin - Quality Eyewear and Services",
    description:
      "Clariviz by Ben Franklin, India's No.1 hospital-based optical chain. Explore our range of high-quality, fashionable, and affordable eyewear products.",
    url: UrlLink
  });
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz by Ben Franklin - India’s Leading Hospital-Based Optical Chain | Eyewear Solutions"
        description="Discover Clariviz by Ben Franklin, India's No.1 hospital-based optical chain, offering high-quality, fashionable, and affordable eyewear products. Explore our range of spectacle lenses, sunglasses, and contact lenses available nationwide."
        keywords="Clariviz by Ben Franklin, Ben Franklin optical chain, hospital-based eyewear chain, India optical stores, eyewear products, spectacle lenses, sunglasses, contact lenses, high-quality eyewear, affordable eyewear, fashionable eyewear, Clariviz optical solutions, optical dispensing in India, Ben Franklin eyewear services, Clariviz partnership, optical career opportunities, Clariviz Store Locator"
        name="Clariviz"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="Lens Color header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz By Ben Franklin
        </h1>
        <p className="text-base text-black leading-8 w-[98%]">
          Clariviz is available at Ben Franklin, India&apos;s No.1
          hospital-based optical chain, having a presence across 25 states, 250
          cities with 500+ stores in partnership with eye hospitals and eye
          clinics, across the country. Ben Franklin serves all age groups of
          customers with its 20+ private label brands. It offers comfortable,
          good quality, fashionable and affordable eyewear ranging from
          Spectacle Frames and Lenses to Sunglasses and Contact Lenses; the
          Brand services over 1 million customers each year.
        </p>
      </div>
      <div className="mb-2 flex flex-col space-y-6 px-custom-padding">
        <h1 className="text-3xl text-primary font-bold">Ben Franklin</h1>
        <p className="text-base text-black leading-8 w-[98%]">
          Ben Franklin is India's largest hospital-based optical chain that
          offers international-standard optical dispensing to the country.
          Working with eye hospitals and clinics in metropolitan cities and
          small towns, we bring our in-depth experience in understanding the
          requirements of varied patients, to offer high-quality, fashionable
          and affordable eyewear. Our products and brands are sourced by
          partnering with the best designers and production lines globally,
          besides the several international eyewear brands we have in store.
        </p>
      </div>
      <div className="py-6 px-custom-padding">
        <button className="btn btn-secondary">
          <a
            href="https://www.benfranklin.in/"
            target="_blank"
            rel="noreferrer"
          >
            Find out more
          </a>
        </button>
      </div>
      <ImageTextLayout
        imageSrc={BecomePartner}
        title="Become a Clariviz Partner"
        description="As a leading manufacturer of spectacle lenses, we provide unparalleled quality, innovation, 
            and support to our business partners. By collaborating with us, you gain access to our extensive range of 
            premium lenses, cutting-edge technology, and expert insights, enabling you to meet the diverse needs of your customers."
        description2="Experience the advantages of a partnership with us and grow your business with confidence. Become a business partner today and join us in achieving excellence."
        buttonTxt="Become a Clariviz Partner"
        reverse
      />
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
      <ImageTextLayout
        imageSrc={Careers}
        title="Careers at Clariviz"
        description="We're a dynamic and innovative eyewear company which values its 
        employees and is committed to creating a positive, inclusive work environment. 
        If you're passionate about eyewear and looking for an exciting and 
        upwardly-mobile career opportunity, then you're welcome to apply!"
        buttonTxt="Send your CV"
        reverse
      />
    </section>
  );
};

export default ClarivizByBenFranklin;
