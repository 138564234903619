import ClarivizMainImg from "../assets/images/zseriesDS/CLARIVIZ Z SERIES DS.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import headerImg from "../assets/images/zseriesDS/headerImg.webp";
import { ZSeriesDSData } from "../utils/ClarivizSeriesData";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizZSeriesDS = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.ZSERIESDS);
  const threeColumnMap = ZSeriesDSData.reduce((acc, item, index) => {
    if (index % 3 === 0) {
      acc.push([item]);
    } else {
      acc[acc.length - 1].push(item);
    }
    return acc;
  }, [] as Array<typeof ZSeriesDSData>).map(([first, second, third]) => ({
    first,
    second,
    third,
  }));
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz Z Series DS Lenses",
    description:
      "Clariviz Z Series DS lenses offer premium progressive technology with Dual Side and Binocular Vision Technology, providing stable and comfortable vision for modern users.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Z Series DS - Premium Lenses with Dual Side Technology & Binocular Vision Technology"
        description="Discover Clariviz Z Series DS, the ultimate in premium lens technology featuring Dual Side and 
        Binocular Vision Technology. Designed for modern progressive users, it ensures top-notch vision stability, comfort, 
        and visual quality for all activities."
        keywords="Clariviz Z Series DS, Z Series DS, premium lenses, Dual Side technology, Binocular Vision Technology, 
        progressive lenses, visual stability, progressive lens comfort, modern lenses digital device lenses, 
        visual quality lenses, wide visual fields"
        name="Clariviz Z Series DS"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz Z Series DS premium lenses with Dual Side technology"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Z Series DS
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clariviz Z Series DS is the most premium and personalized lens and the
          only one that uses Dual Side technology, along with Binocluar Vision
          Technology. The lenses are made to fit the modern and high demand
          lifestyle of nowadays, where progressive users have a busy schedule of
          work, leisure, indoor and outdoor activities. With Clariviz Z Series
          DS lenses, wearers can do everything they need with the guarantee of a
          highly stable, comfortable, and quality vision.
        </p>
        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ClarivizMainImg} alt="Clariviz Z Series DS lenses" />
        </div>
        {/* 3 Column */}
        <div className="pb-12 w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Modern progressive users who want top-of-the-range vision and
                aesthetics.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Users who have a demand for huge visual fields.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Available for all type of prescriptions and addition powers.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Enhanced visual quality for all viewing zones.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Reduced swim effect, providing very stable vision for near,
                intermediate and distance areas.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Easy to adapt.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Highly comfortable, even during digital devices usage.
              </li>
              <li className="list-disc text-base text-black  w-full md:w-[98%]">
                Thinner lenses.
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-full flex flex-col gap-4">
                <li className=" list-disc text-base text-black w-full">
                  <div>14 mm | 15 mm | 16 mm | 17 mm | 18 mm</div>
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black  w-full md:w-[98%]">
                  Fully Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Technologies */}
        <div>
          <h1 className="text-3xl text-primary font-bold">
            Clariviz Z Series DS Technologies
          </h1>
          {/* Technologies with reduce functions */}
          <div className="hidden md:pt-8 md:flex md:flex-col">
            {threeColumnMap.map((row, index) => (
              <div
                key={index}
                className={`grid grid-cols-3 ${
                  index % 2 !== 0 ? "bg-slate-400/10" : ""
                }`}
              >
                {[row.first, row.second, row.third].map(
                  (item, colIndex) =>
                    item && (
                      <div
                        key={colIndex}
                        className={`${
                          index === 0
                            ? colIndex === 0
                              ? "border "
                              : "border-r border-t border-b"
                            : colIndex === 0
                            ? "border-b border-l border-r"
                            : "border-r border-b"
                        } p-[0.85rem]`}
                      >
                        <div className="flex items-center gap-x-4">
                          <div className="w-[24%] h-[24%]">
                            <img
                              src={item.imgSrc}
                              alt={item.altTxt}
                              className="w-full h-full"
                            />
                          </div>
                          <div className="font-bold text-primary text-base">
                            {item.title}
                          </div>
                        </div>
                        <p className="pt-4 tracking-wide">{item.description}</p>
                      </div>
                    )
                )}
              </div>
            ))}
          </div>
          {/* Mobile screens */}
          <div className="py-8 md:hidden">
            {ZSeriesDSData.map((item, index) => (
              <div
                key={index}
                className={`grid grid-cols-1 ${
                  index % 2 !== 0 && "bg-slate-400/10"
                }
                `}
              >
                <div
                  className={`${
                    index % 2 === 0 ? "border" : "border-l border-r"
                  } p-[0.85rem]`}
                >
                  <div className="flex items-center gap-x-4">
                    <div className="w-[24%] h-[24%]">
                      <img
                        src={item.imgSrc}
                        alt={item.altTxt}
                        className="w-full h-full"
                      />
                    </div>
                    <div className="font-bold text-primary text-base">
                      {item.title}
                    </div>
                  </div>
                  <p className="pt-4 tracking-wide">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizZSeriesDS;
