import headerImg from "../assets/images/pixeldigi/headerImg.webp";
import pixelDigiPic from "../assets/images/pixeldigi/pixeldigi.webp";
import pixelDigiStandard from "../assets/images/pixeldigi/pixeldigi-standard.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const PixelDigi = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.PIXELDIGI);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Pixel Digi Lenses",
    description:
      "Pixel Digi lenses are designed to relieve eye strain and discomfort from prolonged close-up tasks, making them ideal for remote workers and office professionals.",
    brand: {
      "@type": "Brand",
      name: "Clariviz",
    },
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Pixel Digi - Relieve Digital Eye Strain for Remote Workers & Professionals"
        description="Discover Pixel Digi lenses, designed to alleviate eye strain from prolonged close-up tasks. Ideal for remote workers, office professionals, and more. Explore our Pixel Digi and Standard Lenses."
        keywords="Pixel Digi lenses, digital eye strain relief, close-up task relief, screen time lenses, remote worker lenses, office worker lenses, Pixel Digi Standard Lenses"
        name="Pixel Digi"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Pixel Digi lenses for digital eye strain relief"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Pixel Digi
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Pixel Digi lenses are designed to relieve eye strain and discomfort
          from prolonged close-up tasks like reading, writing, or using digital
          devices. These lenses can be worn by remote workers, office workers,
          health professionals, industrial workers, and many other
          professionals.
        </p>
      </div>
      {/* img */}
      <div className="pt-4 pb-8 px-custom-padding grid grid-cols-1 gap-8 md:grid-cols-2 place-items-center">
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Pixel Digi Lens
          </h3>
          <div>
            <img
              src={pixelDigiPic}
              alt="Pixel Digi advanced digital lenses"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
        <div className="px-6 flex flex-col gap-4">
          <h3 className="uppercase text-primary font-semibold text-lg">
            Standard Lens
          </h3>
          <div>
            <img
              src={pixelDigiStandard}
              alt="Pixel Digi StandardLenses"
              className="w-[100%] h-[100%]"
            />
          </div>
        </div>
      </div>
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
        you're never far from the exceptional products and services you love. Whether you're planning 
        a visit or need to check availability, we're here to make your shopping experience seamless and 
        convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default PixelDigi;
