// tintData.js
const TintData = [
  {
    color: "Brown",
    tints: [
      { percentage: "10%", bgColor: "bg-brown10", textColor: "text-slate-600" },
      { percentage: "15%", bgColor: "bg-brown15", textColor: "text-slate-600" },
      { percentage: "30%", bgColor: "bg-brown30", textColor: "text-slate-600" },
      { percentage: "60%", bgColor: "bg-brown60", textColor: "text-slate-100" },
      { percentage: "75%", bgColor: "bg-brown75", textColor: "text-slate-100" },
      { percentage: "85%", bgColor: "bg-brown85", textColor: "text-slate-100" },
      { percentage: "94%", bgColor: "bg-brown94", textColor: "text-slate-100" },
    ],
    description:
      "Darker tints like brown protect the eyes by cutting glare and preventing eyestrain. The high contrasts make objects more defined and sharper. Excellent for sports and outdoor activities.",
  },
  {
    color: "Grey",
    tints: [
      { percentage: "10%", bgColor: "bg-grey10", textColor: "text-slate-600" },
      { percentage: "15%", bgColor: "bg-grey15", textColor: "text-slate-600" },
      { percentage: "30%", bgColor: "bg-grey30", textColor: "text-slate-600" },
      { percentage: "60%", bgColor: "bg-grey60", textColor: "text-slate-100" },
      { percentage: "75%", bgColor: "bg-grey75", textColor: "text-slate-100" },
      { percentage: "85%", bgColor: "bg-grey85", textColor: "text-slate-100" },
      { percentage: "94%", bgColor: "bg-grey94", textColor: "text-slate-100" },
    ],
    description:
      "These tints are ideal for everyday use due to their versatility, providing neutral colour perception, minimizing glare and eye fatigue and providing comfort to the eyes.",
  },
  {
    color: "Green",
    tints: [
      { percentage: "10%", bgColor: "bg-green10", textColor: "text-slate-600" },
      { percentage: "15%", bgColor: "bg-green15", textColor: "text-slate-600" },
      { percentage: "30%", bgColor: "bg-green30", textColor: "text-slate-600" },
      { percentage: "60%", bgColor: "bg-green60", textColor: "text-slate-100" },
      { percentage: "75%", bgColor: "bg-green75", textColor: "text-slate-100" },
      { percentage: "85%", bgColor: "bg-green85", textColor: "text-slate-100" },
    ],
    description:
      "These tints are suitable across low-light and partially cloudy weather conditions. They reduce glare, improve color perception, contrast and help brighten shadows.",
  },
  {
    color: "Blue",
    tints: [
      { percentage: "10%", bgColor: "bg-blue10", textColor: "text-slate-600" },
      { percentage: "15%", bgColor: "bg-blue15", textColor: "text-slate-600" },
      { percentage: "30%", bgColor: "bg-blue30", textColor: "text-slate-600" },
      { percentage: "60%", bgColor: "bg-blue60", textColor: "text-slate-100" },
      { percentage: "75%", bgColor: "bg-blue75", textColor: "text-slate-100" },
    ],
    description:
      "These color tints help minimize discomfort caused by glare in bright and sunny conditions. They improve color and contour perceptions and are preferred in water or snow-related activities.",
  },
];

export default TintData;
