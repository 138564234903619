import React from "react";
import { Navbar, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";
import { Link } from "react-router-dom";

const Error = () => {
  const UrlLink = UrlConstants.base_url.concat("/404");
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "404 Error - Page Not Found",
    description:
      "404 Error - Page Not Found. The page you are looking for might be under construction or does not exist.",
    url: UrlLink
  });
  return (
    <>
      <SEO
        title="404 Error - Page Not Found | Clariviz"
        description="404 Error - Page Not Found. The page you are looking for might be 
        under construction or does not exist. Navigate back to the home page to find what you need."
        keywords="404 Error, Page Not Found, Clariviz, website error, broken link"
        name="Clariviz"
        type="website"
        canonicallink="https://www.clariviz.com/404"
        structuredData={structuredData}
      />
      <Navbar />
      <div className="h-[92vh] w-full flex flex-col justify-center items-center space-y-4 text-center p-4">
        <h2 className="text-primary text-2xl font-semibold w-[90%] md:w-[50%] mx-auto">
          Oh No! Page you are looking for is under-construction or not available
          at the moment.
        </h2>
        <div className="text-slate-800 font-medium text-lg">
          Go to{" "}
          <span>
            <Link
              className="text-primary hover:font-semibold"
              to={NavStrings.HOME}
            >
              Home
            </Link>
          </span>
        </div>
      </div>
    </>
  );
};

export default Error;
