import ClarivizClearDriveSVImg from "../assets/images/cleardrive-sv/CLARIVIZ CLEARDRIVE SV.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import headerImg from "../assets/images/cleardrive-sv/headerImg.webp";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizClearDriveSV = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.CLEARDRIVESV);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz ClearDrive SV",
    description:
      "Clariviz Cleardrive Single Vision lenses are designed for night driving, providing superior visual acuity and reduced eyestrain with a dedicated night vision zone.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Cleardrive SV - Single Vision Night Driving Lenses for Enhanced Visual Clarity"
        description="Experience unparalleled visual clarity and safety with Clariviz Cleardrive Single Vision lenses, specifically designed for night driving. Featuring a dedicated night vision zone, these lenses provide superior visual acuity and reduce eyestrain, ensuring a comfortable and secure driving experience."
        keywords="Clariviz Cleardrive SV, night vision lenses, single vision driving lenses, driving eyewear, visual acuity lenses, reduced eyestrain lenses, lenses for night driving, Clariviz single vision lenses, prescription lenses for driving, driving glasses"
        name="Clariviz Cleardrive Single Vision Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz ClearDrive SV night vision lenses for driving"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Cleardrive SV
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clariviz Cleardrive Single Vision lenses feature a dedicated night
          vision zone, ensuring comfortable and secure driving. Clariviz
          Cleardrive Single Vision lenses incorporate a night vision zone to
          compensate for the refractive differences between day and night,
          resulting in impeccable visual acuity and reduced eyestrain.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ClarivizClearDriveSVImg} alt="Clariviz ClearDrive SV single vision lenses" />
        </div>
        {/* 3 Column */}
        <div className="w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-2 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Single-vision users who are looking for an option designed for
                driving moments
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Users who feel the need for better and safer vision during a
                night drive
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Good visual quality
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Thinner and lighter lenses
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Oblique astigmatism reduced
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Available in all types of prescriptions and addition powers
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
you're never far from the exceptional products and services you love. Whether you're planning 
a visit or need to check availability, we're here to make your shopping experience seamless and 
convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizClearDriveSV;
