import headerImg from "../assets/images/office/headerImg.webp";
import ClarivizOfficeImg from "../assets/images/office/CLARIVIZ OFFICE.webp";
import StoreImg from "../assets/images/store-locator.jpg";
import { ImageTextLayout, SEO } from "../components";
import { NavStrings, UrlConstants } from "../constants";

const ClarivizOffice = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.CLARIVIZOFFICE);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz Office Lenses",
    description:
      "Clariviz Office lenses are designed for professionals and presbyopes, offering enhanced vision for near and intermediate distances, digital device comfort, and improved posture.",
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz Office Lenses - Enhanced Vision for Professionals and Digital Comfort"
        description="Discover Clariviz Office lenses, designed to enhance near and 
        intermediate vision for professionals, remote workers, and office workers. 
        These lenses provide comfort during extended digital device use and improve 
        posture and visual ergonomics for presbyopes."
        keywords="Clariviz Office lenses, professional lenses, near and intermediate vision lenses, 
        office lenses, presbyopia correction, digital comfort lenses, 
        lenses for remote workers, progressive lenses for office use, ergonomic vision correction"
        name="Clariviz Office Lenses"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={headerImg}
          alt="Clariviz Office lenses for professionals and digital comfort"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz Office
        </h1>
        <p className="text-base text-black leading-8 w-full md:w-[98%]">
          Clariviz Office is a versatile solutions for professionals who need
          varifocal vision correction tailored to their specific working
          environment. With configurations that enhance near and intermediate
          vision, these lenses can be worn by remote workers, office workers,
          health professionals, industrial workers, and many other
          professionals.
        </p>

        <div className="py-4 w-full md:w-3/5 mx-auto">
          <img src={ClarivizOfficeImg} alt="Clariviz Office lenses for near and intermediate vision" />
        </div>
        {/* 3 Column */}
        <div className="w-full grid grid-cols-1 gap-8 md:gap-4 md:grid-cols-3 md:justify-between md:p-8">
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Ideal Wearer</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Presbyopes who have an extended usage of the near and
                intermediate vision, especially during working hours
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                New presbyopes
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-bold text-xl text-black">Benefits</p>
            <ul className="w-full md:w-[90%] flex flex-col gap-4">
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Peak vision for intermediate and near distances, including
                during the use of digital devices
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Enhanced postural ergonomics, minimizing unnecessary head
                movements
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Comfortable option for digital devices usage
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Smooth and dynamic transition between the near and the
                intermediate zones
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Easy adaptation
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Elimination of peripheral blur
              </li>
              <li className="list-disc text-base text-black w-full md:w-[98%]">
                Available for all types of prescriptions and addition powers
              </li>
            </ul>
          </div>
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">
                MFH (Minimum Fitting Height)
              </p>
              <ul className="w-full md:w-[90%] flex flex-col gap-4">
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  14mm | 18 mm
                </li>
              </ul>
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-bold text-xl text-black">Personalization</p>
              <ul>
                <li className="list-disc text-base text-black w-full md:w-[98%]">
                  Personalized Progressive Lens
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
you're never far from the exceptional products and services you love. Whether you're planning 
a visit or need to check availability, we're here to make your shopping experience seamless and 
convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ClarivizOffice;
