import CastleBlue1 from "../assets/images/castlebluecoating/castleblue-1.jpg";
import CastleBlue2 from "../assets/images/castlebluecoating/castleblue-2.jpg";
import HeaderImg from "../assets/images/castlebluecoating/castlebluecoating-header.jpg";
import { ImageTextLayout, SEO } from "../components";
import StoreImg from "../assets/images/store-locator.jpg";
import { NavStrings, UrlConstants } from "../constants";

const ULTIPROBlueCoating = () => {
  const UrlLink = UrlConstants.base_url.concat(NavStrings.ULTIPROBLUECOATING);
  const structuredData = JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: "Clariviz ULTIPRO Bluecut Coating",
    description:
      "Clariviz ULTIPRO Bluecut coating offers advanced blue light protection and antireflective properties to reduce glare and digital eye strain for improved visual comfort and long-term eye health.",
    brand: {
      "@type": "Brand",
      name: "Clariviz",
    },
    url: UrlLink
  });
  
  return (
    <section className="w-[100%] max-w-screen-2xl mx-auto">
      <SEO
        title="Clariviz ULTIPRO Bluecut Coating"
        description="Explore Clariviz ULTIPRO Bluecut coating, an advanced lens treatment offering premium antireflective properties and blue light protection. Learn how it reduces glare, alleviates digital eye strain, and enhances overall eye comfort."
        keywords="ULTIPRO Bluecut coating, Clariviz, blue light protection, 
        antireflective coating, blue light filter lenses, 
        reduce digital eye strain, premium lens coatings, 
        eye comfort, lens technology, digital screens, Clariviz lens technology, 
        blue light blocking lenses, long-term eye health, glare reduction, visual clarity, 
        blue light protection lenses, sleep quality improvement, UV protection lenses"
        name="Clariviz ULTIPRO Bluecut Coating"
        type="website"
        canonicallink={UrlLink}
        structuredData={structuredData}
      />
      <div className="relative w-full h-[30vh] xs:h-[35vh] small:h-[40vh] md:h-[50vh] lg:h-[55vh] xl:h-[60vh]">
        <img
          src={HeaderImg}
          alt="castle coating header pic"
          className="w-[100%] h-[100%] object-cover"
        />
      </div>
      <div className="py-8 flex flex-col space-y-4 px-custom-padding">
        <h1 className="text-2xl md:text-3xl text-primary font-bold">
          Clariviz ULTIPRO Bluecut Coating
        </h1>
        <div className="grid grid-cols-1 gap-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-4">
            <div className="flex flex-col gap-6">
              <p className="text-15pt text-black leading-8 w-[98%]">
                Ben Franklin presents Clariviz ULTIPRO Bluecut coating, an
                innovative lens coating that combines the benefits of a premium
                antireflective coating with additional protection against blue
                light. Blue light is a high-energy wavelength emitted by digital
                screens, LED lighting, and sunlight. While some blue light
                exposure is natural and necessary for regulating sleep-wake
                cycles and mood, overexposure, especially from artificial
                sources, can lead to eye strain, fatigue, and potential
                long-term damage to retinal cells.
              </p>
              <p className="text-15pt text-black leading-8 w-[98%]">
                Clariviz ULTIPRO Bluecut coating’s blue light filtration is
                achieved through the inclusion of specific materials or
                additives or filters in the coating layers. These materials are
                designed to selectively absorb or reflect blue light while
                allowing other wavelengths of light, including beneficial light
                like green and red wavelengths, to pass through. The precise
                composition and design of the coating are engineered to maintain
                optical clarity while effectively blocking harmful blue light.
              </p>
            </div>
            <div className="w-full md:w-2/4 mx-auto">
              <img src={CastleBlue1} alt="ULTIPRO Bluecut coating for blue light protection" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col gap-12">
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">
                  What it does
                </h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-8 w-[98%]">
                    <strong>Reflection reduction: </strong>Like a standard
                    premium antireflective coating, it minimizes reflections and
                    glare, improving visual clarity and comfort.
                  </p>
                  <p className="text-base text-black leading-8 w-[98%]">
                    <strong>Blue light filtration: </strong>The coating
                    selectively filters out a portion of the blue light
                    spectrum, particularly the high-energy, potentially harmful
                    blue-violet wavelengths. By reducing the amount of blue
                    light that reaches the eyes, it helps alleviate digital eye
                    strain, improve sleep quality, and protect against potential
                    retinal damage associated with prolonged blue light
                    exposure.
                  </p>
                </div>
              </div>
              <div className="w-full md:hidden">
                <img src={CastleBlue2} alt="Clariviz ULTIPRO Bluecut lens technology" />
              </div>
              <div className="flex flex-col gap-4">
                <h1 className="text-3xl text-primary font-bold">Benefits</h1>
                <div className="flex flex-col gap-4">
                  <p className="text-base text-black leading-8 w-[98%]">
                    <strong className="text-primary">Eye comfort: </strong>
                    By reducing glare and filtering out harmful blue light, the
                    coating helps reduce eye strain, fatigue, and discomfort
                    associated with prolonged screen time or exposure to
                    artificial lighting.
                  </p>
                  <p className="text-base text-black leading-8 w-[98%]">
                    <strong className="text-primary">Sleep quality: </strong>
                    Blue light exposure, particularly in the evening, can
                    disrupt the body’s natural sleep-wake cycle by suppressing
                    the production of melatonin, a hormone responsible for
                    regulating sleep. By filtering out excessive blue light,
                    especially from electronic devices used before bedtime, the
                    coating can promote better sleep quality and overall
                    well-being.
                  </p>
                  <p className="text-base text-black leading-8 w-[98%]">
                    <strong className="text-primary">
                      Long-term eye health:{" "}
                    </strong>
                    Protection against excessive blue light exposure may help
                    mitigate the risk of developing age-related macular
                    degeneration (AMD) and other retinal disorders associated
                    with prolonged blue light exposure. By investing in lenses
                    with blue light protection, customers can proactively
                    safeguard their eye health and reduce the potential for
                    long-term damage.
                  </p>
                  <p className="text-base text-black leading-8 w-[98%]">
                    <strong className="text-primary">
                      Visual performance:{" "}
                    </strong>
                    The reduction of reflections and glare provided by the
                    premium antireflective coating enhances visual clarity,
                    contrast, and color accuracy, providing an overall improved
                    visual experience for the wearer.
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden md:block md:w-2/4 md:mx-auto">
              <img src={CastleBlue2} alt="Clariviz ULTIPRO Bluecut lens premium lenses" />
            </div>
          </div>
        </div>
      </div>
      {/* Store Locator */}
      <ImageTextLayout
        imageSrc={StoreImg}
        subtitle="Clariviz Store Locator"
        title="Find a Store near You"
        description="With over 500 hospital retail locations and 8000+ mom-and-pop stores nationwide, 
    you're never far from the exceptional products and services you love. Whether you're planning 
    a visit or need to check availability, we're here to make your shopping experience seamless and 
    convenient. Discover your nearest store today!"
        buttonTxt="Show Me Now"
      />
    </section>
  );
};

export default ULTIPROBlueCoating;
